import type { IconProps } from '@/components/atoms/Icon';
import { Icon } from '@/components/atoms/Icon';

import styles from '@/components/atoms/Button/index.module.scss';

export function ButtonIconComponent({
  id,
  color = '#FFFFFF',
  size = 24,
}: {
  readonly id: IconProps['id'];
  readonly size?: IconProps['width'];
  readonly color?: IconProps['color'];
}): JSX.Element {
  return <Icon className={styles.Icon} id={id} legacy={false} color={color} width={size} height={size} />;
}

ButtonIconComponent.displayName = 'ButtonIconComponent';
