export type ButtonSizes = 'XS' | 'S' | 'M' | 'L';

export enum DesignType {
  PRIMARY = 'primary',
  SECONDARY_LIGHT = 'secondary-light',
  SECONDARY_DARK = 'secondary-dark',
  CTA_LIGHT = 'cta-light',
  CTA_DARK = 'cta-dark',
}

export const ICON_SIZE_MAP = new Map([
  [
    DesignType.PRIMARY,
    new Map([
      ['L', 24],
      ['M', 24],
      ['S', 20],
      ['XS', 16],
    ]),
  ],
  [
    DesignType.SECONDARY_DARK,
    new Map([
      ['L', 20],
      ['M', 16],
      ['S', 16],
      ['XS', 12],
    ]),
  ],
  [
    DesignType.SECONDARY_LIGHT,
    new Map([
      ['L', 20],
      ['M', 16],
      ['S', 16],
      ['XS', 12],
    ]),
  ],
  [
    DesignType.CTA_DARK,
    new Map([
      ['L', 16],
      ['M', 16],
      ['S', 16],
      ['XS', 16],
    ]),
  ],
  [
    DesignType.CTA_LIGHT,
    new Map([
      ['L', 16],
      ['M', 16],
      ['S', 16],
      ['XS', 16],
    ]),
  ],
]);
