import type { ButtonBaseProps } from '@/components/atoms/Button';
import type { IconProps } from '@/components/atoms/Icon';

import { ButtonIconComponent } from '@/components/atoms/Button/components/ButtonIconComponent';
import { LoadingIndicator } from '@/components/atoms/LoadingIndicator';

import styles from '@/components/atoms/Button/index.module.scss';

export type ButtonWithIconOnlyProps = ButtonBaseProps & {
  readonly consolidatedClassNames: string;
  readonly iconSize: IconProps['width'];
  readonly iconColor: IconProps['color'];
};

export function ButtonWithIconOnly({
  type,
  consolidatedClassNames,
  disabled,
  iconId = 'arrows/arrow-right',
  iconSize,
  iconColor,
  loading,
  ...props
}: ButtonWithIconOnlyProps): JSX.Element {
  return (
    <button
      // eslint-disable-next-line react/button-has-type -- incorrect rule
      type={type}
      className={consolidatedClassNames}
      disabled={disabled}
      {...props}
    >
      <div className={styles.ButtonContainer}>
        {loading ? (
          <LoadingIndicator className={styles.ButtonLoading} />
        ) : (
          <ButtonIconComponent id={iconId} size={iconSize} color={iconColor} />
        )}
      </div>
    </button>
  );
}

ButtonWithIconOnly.displayName = 'ButtonWithIconOnly';
