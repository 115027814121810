import type { HTMLAttributes } from 'react';
import React from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

export type LoadingIndicatorProps = HTMLAttributes<HTMLDivElement>;

export function LoadingIndicator({ className, children, ...props }: LoadingIndicatorProps): JSX.Element {
  return <div className={classNames(styles.LoadingIndicator, styles.DotsFlashing, className)} {...props} />;
}
LoadingIndicator.displayName = 'LoadingIndicator';
