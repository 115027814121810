import axios from 'axios';

const defaultOptions = {
  headers: {
    'Content-type': 'application/json',
  },
  method: 'GET',
};

const requestInterceptor = function (config) {
  return config;
};

const successResponseInterceptor = function (res) {
  return res;
};

const errorResponseInterceptor = function (err) {
  throw err;
};

function FetchJson(config) {
  const agent = axios.create(Object.assign(defaultOptions, config));
  agent.interceptors.request.use(requestInterceptor);
  agent.interceptors.response.use(successResponseInterceptor, errorResponseInterceptor);
  return agent;
}

export default FetchJson;
