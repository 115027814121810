'use client';

import React from 'react';
import classNames from 'classnames';
import type { ImageProps } from 'next/image';
import Image from 'next/image';
import type { CloudinaryProps } from './cloudinary';
import { cloudinaryLoader, getCloudinarySrc } from './cloudinary';

export interface ImgProps extends ImageProps, CloudinaryProps {
  readonly provider?: 'cloudinary';
}

export function Img({ className, format, src, provider, alt, ...props }: ImgProps): JSX.Element {
  const providerProps: Pick<ImageProps, 'src' | 'loader'> = { src };
  if (provider === 'cloudinary' && typeof src === 'string') {
    providerProps.src = getCloudinarySrc(src);
    providerProps.loader = cloudinaryLoader({ format });
  }
  return <Image className={classNames(Img.displayName, className)} alt={alt} {...providerProps} {...props} />;
}

Img.displayName = 'Img';

export default Img;
