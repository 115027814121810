import type { ImageLoader } from 'next/image';

export const CLOUDINARY_PATH = 'https://res.cloudinary.com/fxtr/image/upload';
export const CLOUDINARY_LANDING_DIR = 'landing';

export interface CloudinaryProps {
  /**
   * @default auto serve the best format for the browser that requests the image
   * @note For `svg` extensions the format will default to `svg` if not specified otherwise and the quality will be set to 100.
   */
  readonly format?: 'auto' | 'svg' | 'webp' | 'jpg' | 'png';
}

/**
 * Builds a image variation link.
 * From next/image code.
 *
 * @link https://nextjs.org/docs/app/api-reference/next-config-js/images#cloudinary
 */
export const cloudinaryLoader: (cloudinaryProps?: CloudinaryProps) => ImageLoader =
  ({ format: f } = {}) =>
  ({ src, width, quality: q }) => {
    let format = f;
    let quality = q || 'auto';
    if (!format) {
      format = 'auto';
      const ext = src.split('.').pop();
      if (ext === 'svg') {
        format = 'svg';
        quality = 100;
      }
    }
    const relSrc = src.replace(CLOUDINARY_PATH, '');
    const params = [`f_${format}`, 'c_limit', `w_${width}`, `q_${quality}`];
    const paramsString = `${params.join(',')}`;
    return `${CLOUDINARY_PATH}/${paramsString}${relSrc}`;
  };

export const checkCloudinarySrc = (src: string): void => {
  const isAbsolutePath = src.startsWith('http');
  if (!isAbsolutePath) {
    if (!src.startsWith('/')) {
      // consistent with NextJs default behavior
      throw new TypeError(`Relative image paths have to start with '/'. Src provided - ${src}`);
    }
  } else if (!src.startsWith(CLOUDINARY_PATH)) {
    throw new TypeError(
      `Absolute image paths with provider cloudinary have to start with cloudinary URL. Src provided - ${src}`
    );
  }
};

export const getCloudinarySrc = (src: string): string => {
  checkCloudinarySrc(src);
  const isAbsolutePath = src.startsWith('http');
  if (isAbsolutePath) return src;
  return `/${CLOUDINARY_LANDING_DIR}${src}`;
};
