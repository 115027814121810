import less from './fixter-theme.json';

const misc = {
  defaultBorderRadius: '8px',
  // @deprecated, use `Grid`
  gutter: {
    xs: 30,
    sm: 40,
  },
  blockElementShadow: '0 2px 16px 0 rgba(50, 56, 62, 0.08)',
  buttonShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.32)',
  insetShadow: 'inset 0 -2px 0 0 #e6ecf5',
  cardShadow: '0 5px 12px 0 rgba(50, 56, 62, 0.16)',
  cardShadowBig: '0 16px 32px 0 rgba(50, 56, 62, 0.16)',
  fontWeight: {
    light: 200,
    semiLight: 300,
    regular: 400,
    slightlyBold: 500,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  /**
   * Ant design media queries
   * https://ant.design/components/grid/#Col
   *
   * @deprecated use `src/styles/variables/_breakpoints.scss`
   */
  mediaQueryBreakpoints: {
    xs: 0,
    xs360: 360,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    container: 1440,
    xxl: 1600,
    containerLarge: 1920,
    // New grid Breakpoints
    extraSmall: 0,
    small: 600,
    medium: 905,
    large: 1240,
  },
};

export { misc };

const vars = {
  ...less,
  ...misc,
  edgePaddingBreakpoints: {
    xs: 20,
    sm: 20,
    md: 44,
    lg: 48,
    xl: 48,
    container: 80,
    // the rest affects only the large container
    xxl: 68,
    containerLarge: 136,
  },
  // section top/bottom spacing; the grid gutter will add half it's size to this spacing
  sectionSpacingBreakpoints: {
    xs: 40,
    sm: 40,
    md: 48,
    lg: 60,
    xl: 72,
    container: 72,
    xxl: 72,
    containerLarge: 72,
  },
};

export default vars;
