'use client';

import type { HTMLAttributes } from 'react';
import classNames from 'classnames';
import config from 'config';
import { isBrowser } from '@/util';
import { logError } from '@/util/logger';
import styles from './index.module.scss';

// Used for cache busting
export const ICON_VERSION = config.get<string>('public.cache.icons.version');

export interface IconProps extends HTMLAttributes<HTMLSpanElement> {
  readonly id: string;
  readonly color?: string;
  readonly width?: number;
  readonly height?: number;
  readonly legacy?: boolean;
}

const ICON_SET = new Set();

const DEFAULT_ICON_WIDTH = 24;
const DEFAULT_ICON_HEIGHT = DEFAULT_ICON_WIDTH;

const downloadSvgIcon = async (id: string, path: string) => {
  /** @TODO  Only append to DOM when svg is found - error handle */
  if (isBrowser && !ICON_SET.has(id)) {
    ICON_SET.add(id);

    try {
      const svg = await fetch(`/${path}/${id}.svg?version=${ICON_VERSION}`).then((data) => data.text());

      const wrapper = document.createElement('div');
      wrapper.setAttribute('id', id);
      wrapper.style.display = 'none';
      wrapper.style.visibility = 'hidden';

      document.body.append(wrapper);

      const parser = new DOMParser();
      const svgRoot = parser.parseFromString(svg, 'image/svg+xml');

      // add appropriate attributes to the svg root element
      const svgElement = svgRoot.getElementsByTagName('svg')[0];
      svgElement.setAttribute('id', `svg-${id}`);
      svgElement.setAttribute('viewBox', `0 0 ${DEFAULT_ICON_WIDTH} ${DEFAULT_ICON_HEIGHT}`);

      wrapper.appendChild(svgElement);
    } catch (err) {
      logError('Requested icon not found', { id, err }, true);
    }
  }
};

/**
 * @NOTE To use new Clint designed icons, set the 'legacy' prop to 'false'
 *
 * Currently the default 'legacy' is 'true' so as to avoid breaking existing use of the current icons
 */
export function Icon({
  className,
  id,
  color,
  width = DEFAULT_ICON_WIDTH,
  height,
  legacy = true,
  ...props
}: IconProps): JSX.Element {
  const path = legacy ? 'img/svg-icons' : 'icons';
  downloadSvgIcon(id, path);
  const usedHeight = height || width;
  return (
    <span
      {...props}
      className={classNames(styles.icon, ['qvIcon', className])}
      style={{
        width: `${width}px`,
        height: `${usedHeight}px`,
      }}
      aria-hidden="true"
    >
      <svg width={`${width}px`} height={`${usedHeight}px`}>
        <use
          viewBox={`0 0 ${width || DEFAULT_ICON_HEIGHT} ${usedHeight || DEFAULT_ICON_HEIGHT}`}
          width={`${width}px`}
          height={`${usedHeight}px`}
          href={`#svg-${id}`}
          style={{
            color,
          }}
        />
      </svg>
    </span>
  );
}

Icon.displayName = 'Icon';
