import Cookies from 'js-cookie';
import { omit } from 'lodash';
import type { Segment } from '@fixter/i18n';
import config from 'config';
import { getStringUrlQueryParams, logError } from '$util/index';
import type { RescheduleProperties, TrackingPageProperties } from '$quantum/organisms/BookingHistory/types';
import type { ILineItem } from '$apollo/gql/basket';
import type { AnalyticsServicePayload, PartialAnalyticsPayload } from '../types';
import { AnalyticsServiceFieldKey, AnalyticsServiceFieldName, AnalyticsServiceFieldType } from '../types';

const getFormatCnDOption = (str: string) => str.replace('&', 'n');

function getDeviceType() {
  const width = window.innerWidth;

  if (width <= 767) {
    return 'mobile';
  }
  if (width <= 1240) {
    return 'tablet';
  }
  return 'desktop';
}

const buildAnalyticsPayload = (
  sessionId: string,
  data: PartialAnalyticsPayload,
  checkoutSessionId?: string
): string => {
  const payload: AnalyticsServicePayload = {
    ...data,
    sessionId,
    checkoutSessionId,
    source: 'landing',
    clientTimestamp: new Date().toISOString(),
  };

  return JSON.stringify(payload);
};

/**
 * @deprecated Use `internalAnalyticsClient` instead.
 */
const sendAnalytics = async (data: PartialAnalyticsPayload) => {
  const isInternalAnalyticsEnabled = config.get('public.internalAnalytics.enabled');

  if (!isInternalAnalyticsEnabled) return;
  const sessionId = Cookies.get('fixterAnalyticsSessionId');

  if (!sessionId) {
    logError('Client: Attempting to send analytics event without analytics session id', data);
    return;
  }

  const checkoutSessionIdCookie = Cookies.get('fixterSessionId');

  const analyticsData = buildAnalyticsPayload(sessionId, data, checkoutSessionIdCookie);

  const analyticIdentifier = data.fieldKey || '';

  try {
    if (!window.navigator.sendBeacon(`/api/analytics?${analyticIdentifier}`, analyticsData)) {
      logError(`Unable to send internal analytics for: ${analyticIdentifier || 'unknown'}`);
    }
  } catch (err) {
    logError(`Failed to send to internal analytics: ${JSON.stringify(err)}`);
  }
};

/**
 * Tracking the Google Analytics Lifecycle
 * @deprecated moved to App Router
 */
const sendAnalyticsPageView = (): void => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.GOOGLE_ANALYTICS,
    fieldKey: AnalyticsServiceFieldKey.PAGE_VIEW,
    fieldValue: encodeURIComponent(
      window.location.hostname + window.location.pathname + window.location.search
    ),
    fieldType: AnalyticsServiceFieldType.STRING,
  };
  sendAnalytics(payload);
};

const sendAnalyticsGclid = (): void => {
  const { gclid } = getStringUrlQueryParams();

  if (gclid) {
    sendAnalytics({
      fieldName: AnalyticsServiceFieldName.MARKETING,
      fieldKey: AnalyticsServiceFieldKey.GCLID,
      fieldValue: gclid,
      fieldType: AnalyticsServiceFieldType.STRING,
    });
  }
};

const sendAnalyticsCheckoutBookingId = (bookingId: string): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.MARKETING,
    fieldKey: AnalyticsServiceFieldKey.BOOKING_ID,
    fieldValue: bookingId,
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

const sendAnalyticsEWOBookingId = (bookingId: string): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.EWO,
    fieldKey: AnalyticsServiceFieldKey.BOOKING_ID,
    fieldValue: bookingId,
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

const sendAnalyticsEWOProposedPageView = (bookingUuid: string): void => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.EWO,
    fieldKey: AnalyticsServiceFieldKey.EWO_PROPOSED_PAGE_VIEW,
    fieldValue: bookingUuid,
    fieldType: AnalyticsServiceFieldType.STRING,
  };
  sendAnalytics(payload);
};

// Send event when customer approves individual product
const sendAnalyticsEWOApproveWorkOperation = (lineItem: ILineItem, bookingId: string): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.EWO,
    fieldKey: AnalyticsServiceFieldKey.EWO_PROPOSED_APPROVE,
    fieldValue: JSON.stringify({ bookingId, lineItem }),
    fieldType: AnalyticsServiceFieldType.JSON,
  });
};

// Send event when customer declines individual product
const sendAnalyticsEWODeclineWorkOperation = (lineItem: ILineItem, bookingId: string): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.EWO,
    fieldKey: AnalyticsServiceFieldKey.EWO_PROPOSED_DECLINE,
    fieldValue: JSON.stringify({ bookingId, lineItem }),
    fieldType: AnalyticsServiceFieldType.JSON,
  });
};

// Log analytic when customer has viewed all line items
const sendAnalyticsEWOProposedViewedAllLineItems = (bookingId: string): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.EWO,
    fieldKey: AnalyticsServiceFieldKey.EWO_PROPOSED_VIEWED_ALL,
    fieldValue: bookingId,
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

// Click "I Confirm" and progress to the payment page
const sendAnalyticsEWOProposedPageConfirmClicked = (bookingUuid: string): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.EWO,
    fieldKey: AnalyticsServiceFieldKey.EWO_PROPOSED_PAGE_CONFIRM_CLICKED,
    fieldValue: JSON.stringify({ bookingUuid }),
    fieldType: AnalyticsServiceFieldType.JSON,
  });
};

const sendAnalyticsEWOConfirmPaymentPageView = (
  bookingUuid: string,
  proposedQuoteId: string,
  isEligibleForKlarna: boolean,
  displayKlarnaOption: boolean,
  paymentTotal: number
): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.EWO,
    fieldKey: AnalyticsServiceFieldKey.EWO_CONFIRM_PAYMENT_PAGE_VIEW,
    fieldValue: JSON.stringify({
      bookingUuid,
      proposedQuoteId,
      isEligibleForKlarna,
      displayKlarnaOption,
      paymentTotal,
    }),
    fieldType: AnalyticsServiceFieldType.JSON,
  });
};

const sendAnalyticsEWOPaymentConfirmClicked = (
  bookingUuid: string,
  proposedQuoteId: string,
  paymentMethod: string
): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.EWO,
    fieldKey: AnalyticsServiceFieldKey.EWO_PAYMENT_CONFIRM_CLICKED,
    fieldValue: JSON.stringify({ bookingUuid, proposedQuoteId, paymentMethod }),
    fieldType: AnalyticsServiceFieldType.JSON,
  });
};

const sendAnalyticsEWOConfirmPaymentMethodSelected = (
  bookingUuid: string,
  proposedQuoteId: string,
  paymentMethod: string
): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.EWO,
    fieldKey: AnalyticsServiceFieldKey.EWO_CONFIRM_PAYMENT_METHOD_SELCTED,
    fieldValue: JSON.stringify({ bookingUuid, proposedQuoteId, paymentMethod }),
    fieldType: AnalyticsServiceFieldType.JSON,
  });
};

// Send all approved work operations when the customer confirms the payment (on the confirmation page)
const sendAnalyticsEWOPaymentConfirmAllApproved = (
  bookingUuid: string,
  proposedQuoteId: string,
  lineItems: ILineItem[]
): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.EWO,
    fieldKey: AnalyticsServiceFieldKey.EWO_PAYMENT_ALL_APPROVED,
    fieldValue: JSON.stringify({ bookingUuid, proposedQuoteId, lineItems }),
    fieldType: AnalyticsServiceFieldType.JSON,
  });
};

// Send all declined work operations when the customer confirms the payment (on the confirmation page)
const sendAnalyticsEWOPaymentConfirmAllDeclined = (
  bookingUuid: string,
  proposedQuoteId: string,
  lineItems: ILineItem[]
): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.EWO,
    fieldKey: AnalyticsServiceFieldKey.EWO_PAYMENT_ALL_DECLINED,
    fieldValue: JSON.stringify({ bookingUuid, proposedQuoteId, lineItems }),
    fieldType: AnalyticsServiceFieldType.JSON,
  });
};

const sendAnalyticsEWOSuccessPageView = (bookingUuid: string, quoteId: string | undefined): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.EWO,
    fieldKey: AnalyticsServiceFieldKey.EWO_SUCCESS_PAGE_VIEW,
    fieldValue: JSON.stringify({ bookingUuid, quoteId }),
    fieldType: AnalyticsServiceFieldType.JSON,
  });
};

const sendAnalyticsReauthorisePaymentPageView = (
  bookingId: number,
  quoteId: string,
  paymentTotal: number
): void => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.REAUTHORISE_PAYMENT,
    fieldKey: AnalyticsServiceFieldKey.REAUTHORISE_PAYMENT_PAGE_VIEW,
    fieldValue: JSON.stringify({ bookingId, quoteId, paymentTotal }),
    fieldType: AnalyticsServiceFieldType.JSON,
  };
  sendAnalytics(payload);
};

const sendAnalyticsReauthorisePaymentSubmitClicked = (bookingId: string, quoteId: string): void => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.REAUTHORISE_PAYMENT,
    fieldKey: AnalyticsServiceFieldKey.REAUTHORISE_PAYMENT_SUBMIT_CLICKED,
    fieldValue: JSON.stringify({ bookingId, quoteId }),
    fieldType: AnalyticsServiceFieldType.JSON,
  };
  sendAnalytics(payload);
};

// Tracking user behaviours on landing
const sendBookingTrackingPageInteractions = (
  event: AnalyticsServiceFieldKey,
  trackingPageProperties?: TrackingPageProperties
): void => {
  // remove the content (ReactNode) as it may contain circular references
  const trackingPagePropertiesToSend = omit(
    {
      ...trackingPageProperties,
      collectionDeliveryOption: getFormatCnDOption(trackingPageProperties?.collectionDeliveryOption || ''),
    },
    ['latestMilestone.content']
  );

  const payload = {
    fieldName: AnalyticsServiceFieldName.TRACK_BOOKING,
    fieldKey: event,
    fieldValue: JSON.stringify(trackingPagePropertiesToSend),
    fieldType: AnalyticsServiceFieldType.JSON,
  };
  sendAnalytics(payload);
};

const sendBookingRescheduleInteractions = (
  event: AnalyticsServiceFieldKey,
  rescheduleProperties?: RescheduleProperties
): void => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.TRACK_BOOKING,
    fieldKey: event,
    fieldValue: JSON.stringify(rescheduleProperties),
    fieldType: AnalyticsServiceFieldType.JSON,
  };
  sendAnalytics(payload);
};

// Send when product is unsupported (not available in the areas and/or not compatible with the vehicle)
const sendAnalyticsProductDisplayUnsupported = (sku: Required<ILineItem>['sku']): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.PRODUCT,
    fieldKey: AnalyticsServiceFieldKey.PRODUCT_DISPLAY_UNSUPPORTED,
    fieldValue: sku,
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

// Send when product is an offline quote
const sendAnalyticsProductDisplayOfflineQuote = (sku: Required<ILineItem>['sku']): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.PRODUCT,
    fieldKey: AnalyticsServiceFieldKey.PRODUCT_DISPLAY_OFFLINE_QUOTE,
    fieldValue: sku,
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

/**
 * Send when schedule page address is selected (confirmed)
 */
const sendAnalyticsSchedulePageLoad = (bookingId: string): void => {
  const deviceType = getDeviceType();
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.SCHEDULE_PAGE,
    fieldKey: AnalyticsServiceFieldKey.SCHEDULE_PAGE_LOAD,
    fieldValue: JSON.stringify({ deviceType, bookingId }),
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

/**
 * Send when schedule page address is selected (confirmed)
 */
const sendAnalyticsAddressSelected = (address: string): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.SCHEDULE_PAGE,
    fieldKey: AnalyticsServiceFieldKey.SCHEDULE_PAGE_ADDRESS_SELECTED,
    fieldValue: address,
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

/**
 * Send when schedule page address from the input has been used.
 * @note this doesn't always mean manually typed, it will be triggered if they click "Find address" also.
 */
const sendAnalyticsPostcodeTyped = (postcode: string): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.SCHEDULE_PAGE,
    fieldKey: AnalyticsServiceFieldKey.SCHEDULE_PAGE_POSTCODE_TYPED,
    fieldValue: postcode,
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

/**
 * Send when schedule page address from the input has been used.
 * @note this doesn't always mean manually typed, it will be triggered if they click "Find address" also.
 */
const sendAnalyticsAddressTyped = (): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.SCHEDULE_PAGE,
    fieldKey: AnalyticsServiceFieldKey.SCHEDULE_PAGE_ADDRESS_TYPED,
    fieldValue: 'true',
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

/**
 * Send when schedule page address shows suggestions list.
 */
const sendAnalyticsAddressSuggestions = (length: number): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.SCHEDULE_PAGE,
    fieldKey: AnalyticsServiceFieldKey.SCHEDULE_PAGE_ADDRESS_SUGGESTIONS,
    fieldValue: String(length),
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

/**
 * Send when schedule page address suggestion gets selected.
 */
const sendAnalyticsAddressSuggestionSelected = (): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.SCHEDULE_PAGE,
    fieldKey: AnalyticsServiceFieldKey.SCHEDULE_PAGE_ADDRESS_SUGGESTION_SELECTED,
    fieldValue: 'true',
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

/**
 * Send when schedule page address suggestion gets selected.
 */
const sendAnalyticsAddressFindButtonClicked = (): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.SCHEDULE_PAGE,
    fieldKey: AnalyticsServiceFieldKey.SCHEDULE_PAGE_ADDRESS_FIND_BUTTON_CLICKED,
    fieldValue: 'true',
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

// Send when schedule page transport method selected
const sendAnalyticsTransportMethodSelected = (selectedOption: string): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.SCHEDULE_PAGE,
    fieldKey: AnalyticsServiceFieldKey.SCHEDULE_PAGE_TRANSPORT_METHOD_SELECTED,
    fieldValue: getFormatCnDOption(selectedOption),
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

// Send when schedule page timetable collection slot selected
const sendAnalyticsTimetableCollectionSlotSelected = (slot: string): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.SCHEDULE_PAGE,
    fieldKey: AnalyticsServiceFieldKey.SCHEDULE_PAGE_TIMETABLE_COLLECTION_SLOT_SELECTED,
    fieldValue: slot,
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

// Send when schedule page Customer C&D garage selected
const sendAnalyticsCustomerCnDGarageSelected = (garageId: string): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.SCHEDULE_PAGE,
    fieldKey: AnalyticsServiceFieldKey.SCHEDULE_PAGE_CUSTOMER_CND_GARAGE_SELECTED,
    fieldValue: garageId,
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

// Send when schedule page Customer C&D timeslot selected
const sendAnalyticsCustomerCnDTimeSlotSelected = (slot: string): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.SCHEDULE_PAGE,
    fieldKey: AnalyticsServiceFieldKey.SCHEDULE_PAGE_CUSTOMER_CND_TIME_SLOT_SELECTED,
    fieldValue: slot,
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

// Send when schedule request return out of coverage
const sendAnalyticsOutOfCoverageSchedule = (postcode: string): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.SCHEDULE_PAGE,
    fieldKey: AnalyticsServiceFieldKey.SCHEDULE_PAGE_OUT_OF_COVERAGE,
    fieldValue: postcode,
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

// Send when schedule requests return CustomerC&D flow
const sendAnalyticsCustomerCnDFlow = (): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.SCHEDULE_PAGE,
    fieldKey: AnalyticsServiceFieldKey.SCHEDULE_PAGE_CUSTOMER_CND,
    fieldValue: '',
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

// Send when the customer selected fixter cnd but we onluy cover CustomerC&D in that area
const sendAnalyticsForcedCustomerCnDPresented = (): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.SCHEDULE_PAGE,
    fieldKey: AnalyticsServiceFieldKey.SCHEDULE_PAGE_FORCED_CUSTOMER_CND_PRESENTED,
    fieldValue: '',
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

// Send when the customer choosed to proceed with the forced customer C&D option
const sendAnalyticsForcedCustomerCnDSelected = (): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.SCHEDULE_PAGE,
    fieldKey: AnalyticsServiceFieldKey.SCHEDULE_PAGE_FORCED_CUSTOMER_CND_SELECTED,
    fieldValue: '',
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

// Send when the customer C&D garage list is presented to the customer
const sendAnalyticsGarageListPresented = (): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.SCHEDULE_PAGE,
    fieldKey: AnalyticsServiceFieldKey.SCHEDULE_PAGE_GARAGE_LIST_PRESENTED,
    fieldValue: '',
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

// Send when the slots are presented to the customer
const sendAnalyticsSlotsPresented = (): void => {
  sendAnalytics({
    fieldName: AnalyticsServiceFieldName.SCHEDULE_PAGE,
    fieldKey: AnalyticsServiceFieldKey.SCHEDULE_PAGE_SLOTS_PRESENTED,
    fieldValue: '',
    fieldType: AnalyticsServiceFieldType.STRING,
  });
};

const sendPaymentPageEvents = (event: AnalyticsServiceFieldKey, body?: unknown): void => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.PAYMENT_PAGE,
    fieldKey: event,
    fieldValue: JSON.stringify(body),
    fieldType: AnalyticsServiceFieldType.JSON,
  };
  sendAnalytics(payload);
};

const sendCalloutCardContactUsEvents = (event: AnalyticsServiceFieldKey): void => {
  const page = window.location.pathname;
  const payload = {
    fieldName: AnalyticsServiceFieldName.CALLOUT_CARD_CONTACT_US,
    fieldKey: event,
    fieldValue: JSON.stringify({ page }),
    fieldType: AnalyticsServiceFieldType.JSON,
  };
  sendAnalytics(payload);
};

const sendTeamSupportSectionEvents = (event: AnalyticsServiceFieldKey): void => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.TEAM_SUPPORT_SECTION,
    fieldKey: event,
    fieldValue: '',
    fieldType: AnalyticsServiceFieldType.JSON,
  };
  sendAnalytics(payload);
};

const sendAnalyticsNeedHelpClicked = (): void => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.NEED_HELP,
    fieldKey: AnalyticsServiceFieldKey.NEED_HELP_BUTTON_CLICKED,
    fieldValue: 'true',
    fieldType: AnalyticsServiceFieldType.STRING,
  };
  sendAnalytics(payload);
};

const sendAnalyticsNeedHelpFAQClicked = (question: string): void => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.NEED_HELP,
    fieldKey: AnalyticsServiceFieldKey.NEED_HELP_MODAL_FAQ_CLICKED,
    fieldValue: question,
    fieldType: AnalyticsServiceFieldType.STRING,
  };
  sendAnalytics(payload);
};

const sendAnalyticsBookingPageInteractions = (event: AnalyticsServiceFieldKey, bookingId?: string): void => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.BOOKING_PAGE,
    fieldKey: event,
    fieldValue: JSON.stringify({ bookingId }),
    fieldType: AnalyticsServiceFieldType.JSON,
  };
  sendAnalytics(payload);
};

const sendAnalyticsAddedToBasket = (event: AnalyticsServiceFieldKey, productInfo: any): void => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.QUOTES_PAGE,
    fieldKey: event,
    fieldValue: JSON.stringify(productInfo),
    fieldType: AnalyticsServiceFieldType.JSON,
  };
  sendAnalytics(payload);
};

const sendAnalyticsAddedToBasketWithPriceData = (event: AnalyticsServiceFieldKey, productInfo: any): void => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.QUOTES_PAGE,
    fieldKey: event,
    fieldValue: JSON.stringify(productInfo),
    fieldType: AnalyticsServiceFieldType.JSON,
  };
  sendAnalytics(payload);
};

const sendAnalyticsQuotesPageEvent = (event: AnalyticsServiceFieldKey, value: string): void => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.QUOTES_PAGE,
    fieldKey: event,
    fieldValue: value,
    fieldType: AnalyticsServiceFieldType.STRING,
  };
  sendAnalytics(payload);
};

const sendAnalyticsExperiments = (
  page: string,
  bookingUuid: string,
  activeExperiments: string[],
  proposedQuoteId?: string
): void => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.EXPERIMENTS,
    fieldKey: AnalyticsServiceFieldKey.PAGE_LOAD_EXPERIMENTS,
    fieldValue: JSON.stringify({
      page,
      bookingUuid,
      activeExperiments,
      fixterLanding: Cookies.get('fixterLanding'),
      proposedQuoteId,
    }),
    fieldType: AnalyticsServiceFieldType.JSON,
  };
  sendAnalytics(payload);
};

const sendAnalyticsAbandonedBasketPageView = (sourceQuoteId: string): void => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.QUOTES_PAGE,
    fieldKey: AnalyticsServiceFieldKey.ABANDONED_BASKET_BOOKING_STARTED,
    fieldValue: JSON.stringify({
      sourceQuoteId,
    }),
    fieldType: AnalyticsServiceFieldType.JSON,
  };
  sendAnalytics(payload);
};

const sendAnalyticsAbandonedBasketSuccessPage = (bookingUuid: string): void => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.SUCCESS_PAGE,
    fieldKey: AnalyticsServiceFieldKey.ABANDONED_BASKET_BOOKING_FINALISED,
    fieldValue: JSON.stringify({
      bookingUuid,
      sourceQuoteId: sessionStorage.getItem('sourceQuoteId'),
    }),
    fieldType: AnalyticsServiceFieldType.JSON,
  };
  sendAnalytics(payload);
};

const sendAnalyticsCrossSellConversionSuccess = (bookingUuid: string): void => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.SUCCESS_PAGE,
    fieldKey: AnalyticsServiceFieldKey.CROSS_SELL_CONVERSION_SUCCESS,
    fieldValue: JSON.stringify({
      bookingUuid,
    }),
    fieldType: AnalyticsServiceFieldType.JSON,
  };

  sendAnalytics(payload);
};

const sendAnalyticsSubmitMileage = (segment: Segment, mileage: number): void => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.SUCCESS_PAGE,
    fieldKey: AnalyticsServiceFieldKey.SUBMIT_MILEAGE,
    fieldValue: JSON.stringify({
      segment,
      mileage,
    }),
    fieldType: AnalyticsServiceFieldType.JSON,
  };

  sendAnalytics(payload);
};

const sendAnalyticsDiagnosicCoverageCheck = (
  segment: Segment,
  vrm?: string,
  postcode?: string,
  bookingId?: string
) => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.DIAGNOSTIC,
    fieldKey: AnalyticsServiceFieldKey.COVERAGE_CHECK,
    fieldValue: JSON.stringify({ segment, vrm, postcode, bookingId }),
    fieldType: AnalyticsServiceFieldType.JSON,
  };

  sendAnalytics(payload);
};

const sendAnalyticsDiagnosicOutOfCoverage = (
  segment: Segment,
  vrm?: string,
  postcode?: string,
  bookingId?: string
) => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.DIAGNOSTIC,
    fieldKey: AnalyticsServiceFieldKey.OUT_OF_COVERAGE,
    fieldValue: JSON.stringify({ segment, vrm, postcode, bookingId }),
    fieldType: AnalyticsServiceFieldType.JSON,
  };

  sendAnalytics(payload);
};

const sendAnalyticsDiagnosicCallback = (
  segment: Segment,
  vrm?: string,
  postcode?: string,
  bookingId?: string
) => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.DIAGNOSTIC,
    fieldKey: AnalyticsServiceFieldKey.CALLBACK,
    fieldValue: JSON.stringify({ segment, vrm, postcode, bookingId }),
    fieldType: AnalyticsServiceFieldType.JSON,
  };

  sendAnalytics(payload);
};

const sendAnalyticsOfflineQuoteCoverageCheck = (
  segment: Segment,
  sku?: string,
  vrm?: string,
  postcode?: string,
  bookingId?: string
) => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.OFFLINE_QUOTE,
    fieldKey: AnalyticsServiceFieldKey.COVERAGE_CHECK,
    fieldValue: JSON.stringify({ segment, sku, vrm, postcode, bookingId }),
    fieldType: AnalyticsServiceFieldType.JSON,
  };

  sendAnalytics(payload);
};

const sendAnalyticsOfflineQuoteOutOfCoverage = (
  segment: Segment,
  sku?: string,
  vrm?: string,
  postcode?: string,
  bookingId?: string
) => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.OFFLINE_QUOTE,
    fieldKey: AnalyticsServiceFieldKey.OUT_OF_COVERAGE,
    fieldValue: JSON.stringify({ segment, sku, vrm, postcode, bookingId }),
    fieldType: AnalyticsServiceFieldType.JSON,
  };

  sendAnalytics(payload);
};

const sendAnalyticsOfflineQuoteCallback = (
  segment: Segment,
  sku?: string,
  vrm?: string,
  postcode?: string,
  bookingId?: string
) => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.OFFLINE_QUOTE,
    fieldKey: AnalyticsServiceFieldKey.CALLBACK,
    fieldValue: JSON.stringify({ segment, sku, vrm, postcode, bookingId }),
    fieldType: AnalyticsServiceFieldType.JSON,
  };

  sendAnalytics(payload);
};

export const internalAnalytics = {
  sendAnalyticsCheckoutBookingId,
  sendAnalyticsEWOBookingId,
  sendAnalyticsPageView,
  sendAnalyticsGclid,
  sendBookingTrackingPageInteractions,
  sendAnalyticsEWOProposedPageView,
  sendBookingRescheduleInteractions,
  sendAnalyticsEWOApproveWorkOperation,
  sendAnalyticsEWODeclineWorkOperation,
  sendAnalyticsEWOProposedViewedAllLineItems,
  sendAnalyticsEWOProposedPageConfirmClicked,
  sendAnalyticsEWOConfirmPaymentPageView,
  sendAnalyticsEWOConfirmPaymentMethodSelected,
  sendAnalyticsEWOPaymentConfirmClicked,
  sendAnalyticsEWOPaymentConfirmAllApproved,
  sendAnalyticsEWOPaymentConfirmAllDeclined,
  sendAnalyticsEWOSuccessPageView,
  sendAnalyticsReauthorisePaymentPageView,
  sendAnalyticsReauthorisePaymentSubmitClicked,
  sendAnalyticsProductDisplayUnsupported,
  sendAnalyticsProductDisplayOfflineQuote,
  sendAnalyticsSchedulePageLoad,
  sendAnalyticsAddressSelected,
  sendAnalyticsPostcodeTyped,
  sendAnalyticsAddressTyped,
  sendAnalyticsAddressSuggestions,
  sendAnalyticsAddressSuggestionSelected,
  sendAnalyticsAddressFindButtonClicked,
  sendAnalyticsTransportMethodSelected,
  sendAnalyticsTimetableCollectionSlotSelected,
  sendAnalyticsCustomerCnDGarageSelected,
  sendAnalyticsCustomerCnDTimeSlotSelected,
  sendAnalyticsOutOfCoverageSchedule,
  sendAnalyticsCustomerCnDFlow,
  sendAnalyticsGarageListPresented,
  sendAnalyticsSlotsPresented,
  sendPaymentPageEvents,
  sendCalloutCardContactUsEvents,
  sendTeamSupportSectionEvents,
  sendAnalyticsNeedHelpClicked,
  sendAnalyticsNeedHelpFAQClicked,
  sendAnalyticsBookingPageInteractions,
  sendAnalyticsAddedToBasket,
  sendAnalyticsAddedToBasketWithPriceData,
  sendAnalyticsQuotesPageEvent,
  sendAnalyticsExperiments,
  sendAnalyticsForcedCustomerCnDPresented,
  sendAnalyticsForcedCustomerCnDSelected,
  sendAnalyticsAbandonedBasketPageView,
  sendAnalyticsAbandonedBasketSuccessPage,
  sendAnalyticsCrossSellConversionSuccess,
  sendAnalyticsSubmitMileage,
  sendAnalyticsDiagnosicCoverageCheck,
  sendAnalyticsDiagnosicOutOfCoverage,
  sendAnalyticsDiagnosicCallback,
  sendAnalyticsOfflineQuoteCoverageCheck,
  sendAnalyticsOfflineQuoteOutOfCoverage,
  sendAnalyticsOfflineQuoteCallback,
};
